import React from 'react';
import CarouselCard from '../../../Components/Atomic/CarouselCard';
import Slider from "react-slick";  // Import react-slick
import "slick-carousel/slick/slick.css";  // Import slick-carousel styles
import "slick-carousel/slick/slick-theme.css";  // Import slick-carousel theme
import './HomecarouselCardSec.css'; // Import the custom CSS

const HomecarouselCardSec = () => {
    // Slick slider settings
    const settings = {
        infinite: true,
        autoplay: true,
        speed: 900,
        slidesToShow: 2.5,  // Show 2 full cards and part of the 3rd
        autoplaySpeed: 2000,
        slidesToScroll: 1,  // Scroll 1 card at a time
        responsive: [
            {
                breakpoint: 1024, // At 1024px or less, adjust the number of slides
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600, // At 600px or less, adjust the number of slides
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div className="Container">
            <Slider {...settings}>
    {/* Testimonial 1 */}
    <div>
        <CarouselCard
            bg={'#1260FD'}
            paragraph={"Managing a global workforce was challenging until we found Token Talent. They simplified onboarding, payroll, and compliance across borders. We now have peace of mind and a streamlined process for our international team."}
            name='Emma Johnson'
            title='CEO, TechNova Inc.'
            country='United States'
        />
    </div>
    
    {/* Testimonial 2 */}
    <div>
        <CarouselCard
            bg={'#0B8900'}
            paragraph={"As a startup expanding into multiple countries, Token Talent helped us stay compliant with local regulations while offering a seamless payroll experience. It's been a game changer for our growth."}
            name='Akio Tanaka'
            title='Founder, Kyoto Innovations'
            country='Japan'
        />
    </div>
    
    {/* Testimonial 3 */}
    <div>
        <CarouselCard
            bg={'#D44633'}
            paragraph={"Token Talent's platform enabled us to hire the best talent globally without worrying about contracts, taxes, or benefits. Their expertise in HR solutions has been instrumental in scaling our operations."}
            name='Sophia Meier'
            title='HR Director, Alpine Solutions'
            country='Germany'
        />
    </div>
    
    {/* Testimonial 4 */}
    <div>
        <CarouselCard
            bg={'#F39800'}
            paragraph={"The automated payroll management feature saved us countless hours every month. Token Talent's attention to compliance and detail makes them an essential partner for managing our distributed workforce."}
            name='Carlos Rivera'
            title='COO, Zenith Global'
            country='Mexico'
        />
    </div>
    
    {/* Testimonial 5 */}
    <div>
        <CarouselCard
            bg={'#353535'}
            paragraph={"From onboarding to exit management, Token Talent has everything we need to manage our team efficiently. Their platform ensures a smooth and compliant HR process at every stage of the employee lifecycle."}
            name='Fatima Yusuf'
            title='Managing Director, Sahara Ventures'
            country='Nigeria'
        />
    </div>
</Slider>

            {/* <Slider {...settings}>
                <div>
                    <CarouselCard
                        bg={'#1260FD'}
                        paragraph={"Hi, anything that's country-specific, it's possible that somethin will get missed and problems can happen. Remote has it covered with the correct contracts, payroll, taxes, benefits and everything else along the way. That peace of mind is everything."}
                        name='James' />
                </div>
                <div>
                    <CarouselCard
                        bg={'#0B8900'}
                        paragraph={"Hi, anything that's country-specific, it's possible that somethin will get missed and problems can happen. Remote has it covered with the correct contracts, payroll, taxes, benefits and everything else along the way. That peace of mind is everything."}
                        name='James' />
                </div>
                <div>
                    <CarouselCard
                        bg={'#D44633'}
                        paragraph={"Hi, anything that's country-specific, it's possible that somethin will get missed and problems can happen. Remote has it covered with the correct contracts, payroll, taxes, benefits and everything else along the way. That peace of mind is everything."}
                        name='James' />
                </div>
                <div>
                    <CarouselCard
                        bg={'#F39800'}
                        paragraph={"Hi, anything that's country-specific, it's possible that somethin will get missed and problems can happen. Remote has it covered with the correct contracts, payroll, taxes, benefits and everything else along the way. That peace of mind is everything."}
                        name='James' />
                </div>
                <div>
                    <CarouselCard
                        bg={'#353535'}
                        paragraph={"Hi, anything that's country-specific, it's possible that somethin will get missed and problems can happen. Remote has it covered with the correct contracts, payroll, taxes, benefits and everything else along the way. That peace of mind is everything."}
                        name='James' />
                </div>
            </Slider> */}
        </div>
    );
};

export default HomecarouselCardSec;
