import React, { useEffect } from 'react'
import HomecarouselCardSec from '../../Pages/HomePage/HomeCarouselCard/HomecarouselCardSec'
import HeaderSection from '../../Pages/HomePage/HeaderSection/HeaderSection'
import HomePageCardSection from '../../Pages/HomePage/HomePageCardSection/HomePageCardSection'
import HomeInternationTeam from '../../Pages/HomePage/HomeInternationTeam/HomeInternationTeam'
import HomeGlobalTeam from '../../Pages/HomePage/HomeGlobalTeam/HomeGlobalTeam'
import groupImg from '../../Assets/homepage/GroupImg.png'
import HomeHrSection from './HomeHrSection/HomeHrSection'
import EssSection from './EssSection/EssSection'
import HomeReasonSection from './HomeReasonSection/HomeReasonSection'
import { Helmet } from 'react-helmet';
import Seo from '../../Assets/SEOjson.json'

const HomePage = () => {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    }, []);

    return (
        <>
        <Helmet>
        <title>Token Talent - Global HR Platform</title>
        <meta name="description" content="Token Talent simplifies managing global teams with an all-in-one HR platform for payroll, employment, and more." />
        <meta name="keywords" content="HR platform, global teams, payroll, contractor management, international employment" />
        <meta name="author" content="Token Talent" />
        
        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(Seo)}
        </script>
      </Helmet>
            <HeaderSection />
            <HomePageCardSection />
            <HomecarouselCardSec />
            <div className='bg-lightGray flex justify-center items-center p-10'>
                <img src={groupImg} className='md:max-w-2xl w-full mx-auto' />
            </div>
            <HomeInternationTeam />
            <HomeGlobalTeam />
            <HomeHrSection />
            <EssSection />
            <HomeReasonSection />
        </>
    )
}

export default HomePage
