import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import headerImg from '../../../Assets/homepage/header-img.png';
import Header from '../../../Components/Header/Header';

const HeaderSection = () => {
  return (
    <header>
      <Header
        heading={
          <h1>
            The HR platform <br />
            <TypeAnimation
              sequence={[
                'for global teams',
                2500,
                'for global payroll',
                2500,
                'for multiple locations',
                2500,
                'for multiple Benefits',
                2500,
              ]}
              speed={50}
              style={{ color: '#05FF00' }}
              repeat={Infinity}
            />
          </h1>
        }
        text={
          <p>
            Token Talent makes running global teams simple. HRIS, payroll, international employment, 
            and contractor management, and more — all in one Global HR Platform.
          </p>
        }
        homeImage={headerImg}
        homeImageAlt="Token Talent HR platform illustration"
      />
    </header>
  );
};

// const HeaderSection = () => {
//   return (
//     <>
//       <Header
//         heading={
//           <div>
//             The HR platform <br />
//             <TypeAnimation
//               sequence={[
//                 'for global teams',
//                 2500,
//                 'for global payroll',
//                 2500,
//               ]}
//               speed={50}
//               style={{ color: '#05FF00' }}
//               repeat={Infinity}
//             />
//           </div>
//         }
//         text={
//           <>
//             Token Talent makes running global teams simple. HRIS, payroll, international employment, and contractor management, and more all in one Global HR Platform.
//           </>
//         }
//         homeImage={headerImg} />
//     </>
//   )
// }

export default HeaderSection
