// CarouselCard.js
import React from 'react';
import rightArrow from '../../Assets/homepage/rightArrow.svg';
import userRound from '../../Assets/homepage/userRound.svg';

const CarouselCard = ({ bg, paragraph, name, title, country }) => {
    return (
        <>
            <section className="bg-lightGray p-10">
                <div className="container ">
                    <div className="flex">
                        <div className="h-full p-8 rounded-xl" style={{ backgroundColor: bg }}>
                            <p className="text-xs mb-5 text-white">{paragraph}</p>
                            {/* <button className='text-white text-sm underline flex gap-2 items-center'>Read full story <img src={rightArrow} alt="" /></button> */}
                            <a className="flex items-center mt-4">
                                {/* <img alt="" src={userRound} className="w-12 h-12 flex-shrink-0 object-cover object-center" /> */}
                                <span className="flex-grow flex justify-between pl-2 items-center">
                                    <div>
                                    <h3 className="font-bold text-white">{name}</h3>
                                    <span className="font-medium text-sm text-white">{title}</span>
                                    <p className="text-sm text-white">{country}</p>

                                    </div>
                                   
                                    {/* <p>{title}</p>
                                    <p className="text-sm">{country}</p> */}
                                    <div>
                                    
                                    </div>
                                   
                                    
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CarouselCard;
