import React from 'react'
import Cards from '../../../Components/Atomic/Cards'
import zoom from '../../../Assets/homepage/zoom.svg'
import user from '../../../Assets/homepage/user.svg'
import clipboard from '../../../Assets/homepage/clipboard.svg'
import arrow from '../../../Assets/homepage/arrow.svg'
import calculator from '../../../Assets/homepage/calculator.svg'


const HomePageCardSection = () => {
    return (
        <section className="bg-lightGray p-16">
            <h2 className="text-center text-2xl font-bold mb-8">
                Streamline Recruitment, Payroll, and Employee Management
            </h2>
            <div className="grid grid-cols-1 text-center sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 justify-items-center">
                {/* Find */}
                <article>
                    <Cards
                        icon={zoom}
                        alt="Magnifying glass icon representing finding talent"
                        heading="Find"
                        text="Discover top talent globally for your organization. Easily create job postings and share them with candidates through our recruitment management portal."
                    />
                </article>
                {/* Hire */}
                <article>
                    <Cards
                        icon={clipboard}
                        alt="Clipboard icon representing hiring process"
                        heading="Hire"
                        text="Onboard the best talent seamlessly with our onboarding management portal. Simplify hiring and ensure a smooth process for your recruits."
                    />
                </article>
                {/* Pay */}
                <article>
                    <Cards
                        icon={calculator}
                        alt="Calculator icon representing payroll processing"
                        heading="Pay"
                        text="Automate payroll with our attendance- and leave-based salary calculations. Ensure timely and accurate payments for your team."
                    />
                </article>
                {/* Manage */}
                <article>
                    <Cards
                        icon={user}
                        alt="User icon representing employee management"
                        heading="Manage"
                        text="Organize employee records, manage wages, allowances, dependents, and documentation with our employee management portal."
                    />
                </article>
                {/* Exit */}
                <article>
                    <Cards
                        icon={arrow}
                        alt="Arrow icon representing exit management"
                        heading="Exit"
                        text="Simplify employee exits by automating payroll finalization, document processing, and asset retrieval with our exit management portal."
                    />
                </article>
            </div>
        </section>
    );
};

// const HomePageCardSection = () => {
//     return (
//         <>
//             <div className='bg-lightGray grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 p-16 justify-items-center'>
//                 <Cards
//                     icon={zoom}
//                     heading='Find'
//                     text='The best person for the job could be anywhere in the world. Connect with top jobseekers for your open roles.' />
//                 <Cards
//                     icon={clipboard}
//                     heading='Hire'
//                     text='The best person for the job could be anywhere in the world. Connect with top jobseekers for your open roles.' />
//                 <Cards
//                     icon={calculator}
//                     heading='Pay'
//                     text='The best person for the job could be anywhere in the world. Connect with top jobseekers for your open roles.' />
//                 <Cards
//                     icon={user}
//                     heading='Manage'
//                     text='The best person for the job could be anywhere in the world. Connect with top jobseekers for your open roles.' />
//                 <Cards
//                     icon={arrow}
//                     heading='Exit'
//                     text='The best person for the job could be anywhere in the world. Connect with top jobseekers for your open roles.' />
//             </div>
//         </>
//     )
// }

export default HomePageCardSection
