import React from 'react';
import team from '../../../Assets/homepage/Globalteam.png';
import './HomeGlobalTeam.css';
import { useNavigate } from 'react-router-dom';

const HomeGlobalTeam = () => {
    const navigate = useNavigate();
    return (
        <section className='globalTeams flex flex-col md:flex-row justify-between gap-10 p-6 md:p-10' role="region" aria-labelledby="global-team-title">
            {/* Image Section */}
            <div className='flex justify-center mx-0 md:mx-auto'>
                <img
                    src={team}
                    alt='A diverse global team collaborating virtually using Token Talent’s HR platform'
                    className='max-w-xs md:max-w-md'
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                />
            </div>

            {/* Text Section */}
            <div className='flex flex-col gap-4 justify-center text-center md:text-left mx-0 md:mx-auto'>
                <h1 id="global-team-title" className='text-2xl md:text-4xl text-white font-bold'>
                    Manage your global <br /> team <span className='text-lime'> in one place</span>
                </h1>
                <p className='text-xs md:text-sm text-white'>
                    Whether you work with 1 team member or 1,000, you can bring the <br className='hidden md:inline' /> 
                    whole team together and simplify your HR stack with Token Talent.
                </p>
                <div className='flex justify-center md:justify-start'>
                    <button
                        onClick={() => navigate('/features')}
                        className='bg-white text-darkBlue p-2 md:p-3 w-28 md:w-32 rounded-full text-xs font-medium'
                        aria-label="Explore Token Talent's Features"
                    >
                        See Difference
                    </button>
                </div>
            </div>
        </section>
    );
    // return (
    //     <>
    //         <div className='globalTeams flex flex-col md:flex-row justify-between gap-10 p-6 md:p-10'>
    //             {/* Image Section */}
    //             <div className='flex justify-center mx-0 md:mx-auto'>
    //                 <img src={team} alt='Global Team' className='max-w-xs md:max-w-md' data-aos="zoom-in" data-aos-duration="1500" />
    //             </div>

    //             {/* Text Section */}
    //             <div className='flex flex-col gap-4 justify-center text-center md:text-left mx-0 md:mx-auto'>
    //                 <h1 className='text-2xl md:text-4xl text-white font-bold'>
    //                     Manage your global <br /> team <span className='text-lime'> in one place</span>
    //                 </h1>
    //                 <p className='text-xs md:text-sm text-white'>
    //                     Whether you work with 1 team member or 1,000, you can bring the <br className='hidden md:inline' /> whole team together and simplify your HR stack with Token Talent.
    //                 </p>
    //                 <div className='flex justify-center md:justify-start'>
    //                     <button onClick={() => navigate('/features')} className='bg-white text-darkBlue p-2 md:p-3 w-28 md:w-32 rounded-full text-xs font-medium'>
    //                         See Difference
    //                     </button>
    //                 </div>
    //             </div>
    //         </div>
    //     </>
    // );
};

export default HomeGlobalTeam;
